import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Collezione from "./pages/Collezione";
import Percorsi from "./pages/Percorsi";
import SinglePercorso from "./pages/SinglePercorso";
import Palazzo from "./pages/Palazzo";

const Main = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/collezione" element={<Collezione />} />
            <Route path="/percorsi" element={<Percorsi />} />
            <Route path="/palazzo" element={<Palazzo />} />
            <Route path="/percorsi/:id" element={<SinglePercorso />} />
        </Routes>
    );
};

export default Main;
