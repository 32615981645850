import React  from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AGModalOpen, InfoModalOpen, mainSelector, MenuModalOpen} from "../../store/slices/main.slice";
import HamburgerMenu from "./HamburgerMenu";

const Header = ({version = 'light'}: any) => {

    const dispatch: any = useDispatch();
    const { menuModal } = useSelector(mainSelector);

    const openInfo = () => {
        dispatch(AGModalOpen(false))
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(true))
    }

    const openMenu = () => {
        dispatch(AGModalOpen(false))
        dispatch(MenuModalOpen(true))
    }

    const goToHome = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        dispatch(AGModalOpen(false))
        window.location.href = `/`
    }

    return (
        <header className="flex flex-row items-center justify-between w-full gap-[50px] px-[40px] h-[84px] sm:h-[60px] lg:h-[84px]">
            <div>
                <img onClick={goToHome} src={version === 'light' ? '/images/GNDM acronimo orizzontale.png' : '/images/GNDM acronimo orizzontale.png'} alt="Header GNDM Logo" className="max-h-[20px] cursor-pointer lg:hidden"/>
                <img onClick={goToHome} src={version === 'light' ? '/images/GNDM logo full.png' : '/images/GNDM logo full black.png'} alt="Header GNDM Logo" className="max-h-[30px] cursor-pointer hidden lg:block"/>
            </div>
            <div className="flex flex-row items-center h-max gap-[50px]">
                <button onClick={openInfo} className="w-[10px] h-[25px] min-w-[10px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={version === 'light' ? '/images/info-icon.png' : '/images/info-icon-black.png'} alt="Info icon" className="h-full"/></button>
                <div className="relative"><HamburgerMenu onClick={openMenu}/></div>
            </div>
        </header>
    );
};

export default Header;
