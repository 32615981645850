import React, {useEffect, useState} from 'react';
import { useSpring, animated } from "@react-spring/web";
import {InfoModalOpen, mainSelector, MenuModalOpen} from "../../store/slices/main.slice";
import {useDispatch, useSelector } from 'react-redux';
const HamburgerMenu = ({onClick, version = 'black'}: any) => {
    const { menuModal, infoModal } = useSelector(mainSelector);
    const [active, setActive] = useState(false);
    const dispatch: any = useDispatch();

    useEffect(() => {
        if(menuModal.open === true || infoModal.open === true) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [menuModal]);

    const bar1Style = useSpring({
        top: active ? "50%" : "0%",
        config: { duration: 200, easing: (t) => t * (2 - t) },
        delay: active ? 0 : 800
    });

    const bar3Style = useSpring({
        top: active ? "50%" : "100%",
        rotateZ: active ? "90deg" : "0deg",
        config: { duration: active ? 800 : 200, easing: (t) => t * (2 - t) },
        delay: active ? 200 : 0
    });

    const buttonStyle = useSpring({
        rotateZ: active ? "135deg" : "0deg",
        config: { duration: 800, easing: (t) => t * (2 - t) },
        delay: active ? 200 : 0,
        marginTop: active ? '-7px' : '-10px'
    });

    const handleClick = () => {
        dispatch(MenuModalOpen(!menuModal.open))
        if(infoModal.open === true) {
            dispatch(InfoModalOpen(false))
        }
        setActive(!active);
    };

    return (
        <animated.div
            className={`McButton ${version === 'white' ? 'McButton-b-white' : 'McButton-b-black'} ${active ? "active" : ""}`}
            style={buttonStyle}
            onClick={handleClick}
            data-hamburger-menu
        >
            <animated.b style={bar1Style} />
            <animated.b />
            <animated.b style={bar3Style} />
        </animated.div>
    );
};

export default HamburgerMenu;
