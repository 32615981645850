import { createSlice } from '@reduxjs/toolkit';

interface MainState {
    menuModal: any;
    infoModal: any;
    AGmodal: any;
}

const initialState: MainState = {
    menuModal: {
        open: false
    },
    infoModal: {
        open: false
    },
    AGmodal: {
        open: false
    },
};

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setMenuModalOpen: (state, actions) => {
            state.menuModal.open = JSON.parse(actions.payload);
        },
        setInfoModalOpen: (state, actions) => {
            state.infoModal.open = JSON.parse(actions.payload);
        },
        setAGModalOpen: (state, actions) => {
            state.AGmodal.open = JSON.parse(actions.payload);
        },
    },
});

export const { setMenuModalOpen, setInfoModalOpen, setAGModalOpen } = mainSlice.actions;

export default mainSlice.reducer;

export const mainSelector = (state: any) => state.main;

export function MenuModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setMenuModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function InfoModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setInfoModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function AGModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setAGModalOpen(open));
        } catch (error) {
            console.log(error);
        }
    };
}