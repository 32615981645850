import React from 'react';
import './App.css';
import Main from "./components/Main";
import MenuModalRoot from "./components/modals/MenuModalRoot";
import InfoModalRoot from "./components/modals/InfoModalRoot";
import AGModalRoot from "./components/modals/AGModalRoot";

function App() {
    return (
        <>
            <AGModalRoot/>
            <MenuModalRoot/>
            <Main/>
        </>
    );
}

export default App;
