import React, {useState} from 'react';
import Header from "../layout/Header";
import Button from "../layout/Button";
import { useNavigate } from 'react-router-dom';
import {AnimatePresence, motion} from "framer-motion";

const Home = () => {
    const navigate = useNavigate();
    function handleMouseEnter(divId: string) {
        const div = document.getElementById(divId);
        div!.classList.add('zoom-bg');
        div!.classList.remove('reset-zoom-bg');
    }

    function handleMouseLeave(divId: string) {
        const div = document.getElementById(divId);
        div!.classList.add('reset-zoom-bg');
        div!.classList.remove('zoom-bg');
    }

    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        {
            id: 1,
            content: <div style={{backgroundImage: `url('/images/bg-1.png')`}} className="relative bg-no-repeat bg-cover bg-center w-full h-full">
                <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                <img onClick={handlePrevSlide} src={`/images/gndm_shape_prev.png`} alt="Prev icon" className="absolute left-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center z-[2] w-[65%] text-center sm:pb-0 pb-safe">
                    <img src={`/images/gndm_shape_collezione.png`} style={{opacity: 0}} alt="Shape icon" className="mt-[100px] h-[60px] sm:h-[100px]"/>
                    <h2 className="text-white text-lg">LA COLLEZIONE</h2>
                    <h3 className="text-white text-sm h-[100px]">Ascolta le audioguide della Collezione</h3>
                    <Button text={'Ascolta'} onClick={() => navigate('/collezione')}/>
                    <img src={`/images/gndm_shape_collezione.png`} alt="Shape icon" className="mt-[50px] sm:mt-[100px] h-[40px] sm:h-[100px]"/>
                </div>
                <img onClick={handleNextSlide} src={`/images/gndm_shape_next.png`} alt="Next icon" className="absolute right-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="flex sm:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-center px-[20px]">
                    <div className="flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                </div>
            </div>
        },
        {
            id: 2,
            content: <div style={{backgroundImage: `url('/images/bg-2.png')`}} className="relative bg-no-repeat bg-cover bg-center w-full h-full">
                <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                <img onClick={handlePrevSlide} src={`/images/gndm_shape_prev.png`} alt="Prev icon" className="absolute left-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center z-[2] w-[65%] text-center sm:pb-0 pb-safe">
                    <img src={`/images/gndm_shape_palazzo.png`} style={{opacity: 0}} alt="Shape icon" className="mt-[100px] h-[60px] sm:h-[100px]"/>
                    <h2 className="text-white text-lg">IL PALAZZO</h2>
                    <h3 className="text-white text-sm h-[100px]">Ascolta le audioguide del Palazzo</h3>
                    <Button text={'Ascolta'} onClick={() => navigate('/palazzo')}/>
                    <img src={`/images/gndm_shape_palazzo.png`} alt="Shape icon" className="mt-[50px] sm:mt-[100px] h-[40px] sm:h-[100px]"/>
                </div>
                <img onClick={handleNextSlide} src={`/images/gndm_shape_next.png`} alt="Next icon" className="absolute right-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="flex sm:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-center px-[20px]">
                    <div className="flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                </div>
            </div>
        },
        {
            id: 3,
            content: <div style={{backgroundImage: `url('/images/bg-3.png')`}} className="relative bg-no-repeat bg-cover bg-center w-full h-full">
                <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                <img onClick={handlePrevSlide} src={`/images/gndm_shape_prev.png`} alt="Prev icon" className="absolute left-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center z-[2] w-[65%] text-center sm:pb-0 pb-safe">
                    <img src={`/images/gndm_shape_percorsi.png`} style={{opacity: 0}} alt="Shape icon" className="mt-[100px] h-[60px] sm:h-[100px]"/>
                    <h2 className="text-white text-lg">I PERCORSI</h2>
                    <h3 className="text-white text-sm h-[100px]">Esplora i percorsi tematici del Palazzo e ascolta le audioguide</h3>
                    <Button text={'Ascolta'} onClick={() => navigate('/percorsi')}/>
                    <img src={`/images/gndm_shape_percorsi.png`} alt="Shape icon" className="mt-[50px] sm:mt-[100px] h-[40px] sm:h-[100px]"/>
                </div>
                <img onClick={handleNextSlide} src={`/images/gndm_shape_next.png`} alt="Next icon" className="absolute right-[30px] top-1/2 transform -translate-y-1/2 h-[30px] z-[2] cursor-pointer"/>
                <div className="flex sm:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-center px-[20px]">
                    <div className="flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                </div>
            </div>
        }
    ];


    return (
        <>
            <div className="flex-grow flex items-center justify-center h-[100dvh] w-full bg-black sm:hidden overflow-hidden">
                <div className="w-full h-max absolute top-0 right-0 z-[3] bg-[#e4dbd6]">
                    <Header version="dark"/>
                </div>
                <AnimatePresence>
                    <motion.div
                        key={slides[currentSlide].id}
                        className="w-full h-full flex items-center justify-center"
                        initial={{opacity: 0, x: 100}}
                        animate={{opacity: 1, x: 0, transition: {duration: 0.5}}}
                        exit={{opacity: 0, x: -100, transition: {duration: 0.5}}}
                    >
                        {slides[currentSlide].content}
                    </motion.div>
                </AnimatePresence>
            </div>
            <div className="relative hidden sm:flex flex-col items-start min-h-screen min-w-screen w-full h-full bg-black">
                <div className="w-full h-max absolute top-0 right-0 z-[2] bg-[#e4dbd6]">
                    <Header version="dark"/>
                </div>
                <div className="flex flex-row w-full h-full">
                    <div className="w-[33%] relative flex flex-col items-center justify-center flex-grow h-screen overflow-hidden">
                        <div id="bg1" style={{backgroundImage: `url('/images/bg-1.png')`}} className="absolute top-0 right-0 bg-no-repeat bg-cover bg-center w-full h-screen"/>
                        <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                        <div className="flex flex-col items-center justify-center z-[2] w-[65%] text-center">
                            <h2 className="text-white text-xl sm:text-sm md:text-xl">LA COLLEZIONE</h2>
                            <h3 className="text-white text-base sm:text-xs md:text-base h-[100px]">Ascolta le audioguide della Collezione</h3>
                            <div
                                onMouseEnter={() => handleMouseEnter('bg1')}
                                onMouseLeave={() => handleMouseLeave('bg1')}
                            >
                                <Button text={'Ascolta'} onClick={() => navigate('/collezione')}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-[33%] relative flex flex-col items-center justify-center flex-grow h-screen overflow-hidden">
                        <div id="bg2" style={{backgroundImage: `url('/images/bg-2.png')`}} className="absolute top-0 right-0 bg-no-repeat bg-cover bg-center w-full h-screen"/>
                        <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                        <div className="flex flex-col items-center justify-center z-[2] w-[65%] text-center">
                            <h2 className="text-white text-xl sm:text-sm md:text-xl">IL PALAZZO</h2>
                            <h3 className="text-white text-base sm:text-xs md:text-base h-[100px]">Ascolta le audioguide del Palazzo</h3>
                            <div
                                onMouseEnter={() => handleMouseEnter('bg2')}
                                onMouseLeave={() => handleMouseLeave('bg2')}
                            >
                                <Button text={'Ascolta'} onClick={() => navigate('/palazzo')}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-[33%] relative flex flex-col items-center justify-center flex-grow h-screen overflow-hidden">
                        <div id="bg3" style={{backgroundImage: `url('/images/bg-3.png')`}} className="absolute top-0 right-0 bg-no-repeat bg-cover bg-center w-full h-screen"/>
                        <div className="absolute top-0 right-0 bg-black/35 w-full h-screen"/>
                        <div className="flex flex-col items-center justify-center z-[2] w-[85%] text-center">
                            <h2 className="text-white text-xl sm:text-sm md:text-xl">I PERCORSI</h2>
                            <h3 className="text-white text-base sm:text-xs md:text-base h-[100px]">Esplora i percorsi tematici del Palazzo e ascolta le audioguide</h3>
                            <div
                                onMouseEnter={() => handleMouseEnter('bg3')}
                                onMouseLeave={() => handleMouseLeave('bg3')}
                            >
                                <Button text={'Ascolta'} onClick={() => navigate('/percorsi')}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden sm:flex lg:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/80 h-[60px] flex-row items-center justify-center px-[20px]">
                    <div className="flex flex-row items-center gap-[10px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Home;
